import en from './en';
import de from './de';
import es from './es';

const recources = {
  en,
  de,
  es,
};

export default recources;
