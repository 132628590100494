import React, { FC } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { RouterProvider } from 'react-router';
// eslint-disable-next-line import/no-unresolved
import Settings from 'views/Settings';
// eslint-disable-next-line import/no-unresolved
import SignUp from 'views/SignUp';
// eslint-disable-next-line import/no-unresolved
import Login from 'views/Login';
// eslint-disable-next-line import/no-unresolved
import Dashboard from 'views/Dashboard';

interface RoutesProps {}

const Routes: FC<RoutesProps> = () => {
  const router = createBrowserRouter([
    {
      path: '',
      Component: Dashboard,
      children: [
        { path: 'signup/', Component: SignUp },
        { path: 'login/', Component: Login },
        {
          path: 'settings/',
          Component: SignUp,
        },
        {
          path: 'settings/set/',
          Component: Settings,
        },
      ],
    },
  ]);
  return (
    <RouterProvider router={router} />
  );
};

export default Routes;
