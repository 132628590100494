const login = {
  headline: 'Login',
  name: 'Name',
  email: 'Email',
  error: {
    validation: 'validation Error',
  },
};

export default login;
