import settingsUser from './general/user';
import login from './general/login';
import dataView from './dataView';

const de = {
  settingsUser,
  login,
  dataView,
};

export default de;
