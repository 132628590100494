import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { gql, useQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
);

// interface SensorData {
//     date: number
//     t: number
//     h: number
// }

const gqlSensorData = gql`
  query getSensorData {
    sensorData {
      date
      t
      h
    }
  }
`;

interface SettingsProps {
  user?: string;
}

const Settings: FC<SettingsProps> = ({ user }) => {
  const { t } = useTranslation();
  const { loading, error, data: dataSensor } = useQuery(gqlSensorData);

  const getDate = (data: { date: number }) => DateTime.fromMillis(data.date).toFormat('yyyy LLL dd hh:mm:ss');
  const getTemp = (data: { t: number }) => data.t;
  const getHumid = (data: { h: number }) => data.h;

  const humidData = useMemo(
    () => (dataSensor ? dataSensor.sensorData.map(getHumid) : []),
    [dataSensor],
  );
  const dateData = useMemo(
    () => (dataSensor ? dataSensor.sensorData.map(getDate) : []),
    [dataSensor],
  );
  const tempData = useMemo(
    () => (dataSensor ? dataSensor.sensorData.map(getTemp) : []),
    [dataSensor],
  );

  // console.dir(dateData);
  const data = {
    labels: dateData,
    datasets: [
      {
        label: t('general:humidity'),
        data: humidData,
        fill: false,
        borderColor: 'rgb(75, 0, 192)',
        tension: 0.1,
      },
      {
        label: t('general:temperatur'),
        data: tempData,
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
      },
    ],
    options: {
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            autoSkip: true,
            maxTicksLimit: 20,
          },
        },
        x: {
          ticks: {
            // For a category axis, the val is the
            // index so the lookup via getLabelForValue is needed
            callback(val: string, index: number) {
              // Hide every 2nd tick label
              return index % 1500 === 0 ? val : '';
            },
            color: 'red',
          },
        },
      },
    },
  };

  if (loading) return <div>Loading ...</div>;
  if (error) return <div>ERROR</div>;

  return (
    <>
      <Button>{user}</Button>
      <div className="myName">
        <Line data={data} />
      </div>
    </>
  );
};

export default Settings;
