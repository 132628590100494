import settingsUser from './general/user';
import login from './login';
import dataView from './dataView';

const en = {
  settingsUser,
  login,
  dataView,
};

export default en;
