import React from 'react';
import ReactDOM from 'react-dom/client';
// import dotenv from 'dotenv';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import './index.css';
import './i18n';

import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import Routes from './Routes/Routes';

// dotenv.config();
console.log(process.env);
const client = new ApolloClient({
  // uri: `http://localhost:${process.env.UAPI_PORT}/`,
  uri: 'http://localhost:4047/',
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <ThemeProvider theme={theme}>
    <React.StrictMode>
      <ApolloProvider client={client}>
        <Routes />
      </ApolloProvider>
    </React.StrictMode>
  </ThemeProvider>,
);
